import React, { Component, Suspense } from "react";
import PropTypes from "prop-types";
import AllRoutes from "./routes";
import { connect } from "react-redux";
import Loading from "./pages/loadingpage";
import { pdfjs } from "react-pdf";
import { Navigate } from "react-router-dom";
import JwtProvider from "./customhooks/JwtProvider";
import { GlobalDebug } from "./utils/remove-console";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
// const checkAuth = async() => {
//   const token = localStorage.getItem("accessToken");
//   const [user, setUser] = useState(null);
//   const [oAuthUsed, setOAuthUsed] = useState(false);
//   try {
//     const url = `${process.env.REACT_APP_API_URL}/auth/login/success`;
//     const { data } = await axios.get(url, { withCredentials: true });
//     setUser(data.user._json);
//     setOAuthUsed(true)

//   } catch (err) {
//     setOAuthUsed(false)
//     console.log(err);
//   }

//   if (!token || oAuthUsed==false) {
//     return false;
//   }
//   return true;
// };
// ========================================================================
// const checkAuth = () => {
//   const token = localStorage.getItem("accessToken");
//   if (!token) {
//     console.log("Not logged in");
//     return false;
//   }
//   console.log("token found : ", token?token:data.token);
//   return true;
// };
// ================================================================================
const checkAuth = () => {
  const token = localStorage.getItem("accessToken");
  // const url = `http://localhost:5002/api/auth/google-login/success`;
  // const { data } = axios.get(url, { withCredentials: true });
  // console.log("user : ", data);
  if (!token) {
    console.log("Check auth returns False");
    return false;
  }
  console.log("token found : ", token);
  console.log("Check auth returns True");
  return true;
};

// useEffect(() => {
//   checkAuth();
// });

// =============================================================================

// const [user, setUser] = useState(null);
// const getUser = async () => {
//   try {
//     const url = `http"//localhost:3000/auth/google-login/success`;
//     const { data } = await axios.get(url, { withCredentials: true });
//     setUser(data.user._json);
//   } catch (err) {
//     console.log(err);
//   }
// };

// useEffect(() => {
//   getUser();
// }, []);

const AuthRoute = ({ children }) => {
  checkAuth() ? children : <Navigate to="/signin" />;
};
class App extends Component {
  componentDidMount() {
    (process.env.NODE_ENV === "production" ||
     process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false);
  }
  render() {
    return (
      <React.Fragment>
        <JwtProvider>
          <Suspense
            fallback={
              <div>
                <Loading />
              </div>
            }
          >
            {<AllRoutes />}
          </Suspense>
        </JwtProvider>
      </React.Fragment>
    );
  }
}

App.propTypes = {
  user_id: PropTypes.string,
  userRole: PropTypes.string,
};

function mapStateToProps(state) {
  if (state.user) {
    return {
      user_id: state.user.id,
      userRole: state.user.role,
    };
  }
  return {};
}
export default connect(mapStateToProps, {})(App);
