import {
  SET_CURRENT_JOB,
  SET_CURRENT_JOB_TO_EMPTY,
  SET_ALL_JOBS,
  SET_FILTER_JOBS_VALUES,
  SET_FILTER_JOBS,
  SET_JOB_FILTER_DROPDOWN,
  SET_SAVED_QUESTIONS,
  SET_JOB_CLOSED,
  SET_JOB_OPENED,
  UPDATE_JOB,
  DELETE_JOB,
  Filter_LOCAL_JOB_Filter,
  UPDATETEMPLATE_JOB
} from "./../types.js";
let current_job = {
  job_description: "",
  job_title: "",
  user_id: "",
  client_id: "",
  client_name: "",
  reqruiter_Name: "",
  reqruiter_Id: "",
  reqruiter_Email_Id: "",
};

let initialState = {
  allJobs: [],
  loading: true,
  filter_Dropdown: {
    location: [],
    designation: '',
    functional_area: [],
    employment_type: [],
    client: [],
    active: true,
    close: false,
    subscription:[]
  }
};

export default function company(state = initialState, action = {}, errors) {
  switch (action.type) {

    case 'SET_LOADING':
      return Object.assign({}, state, { loading: true})

    case SET_ALL_JOBS:
      return Object.assign({}, state, {
        // allJobs: action.data.jobs,
        allJobs: action.data.jobs,
        loading: false,
      });

    case SET_CURRENT_JOB:
      console.log("action job", action.data);
      return Object.assign({}, state, { current_job: action.data });

    case SET_CURRENT_JOB_TO_EMPTY:
      return Object.assign({}, state, { current_job: {} });



    case SET_FILTER_JOBS:
      console.log("-----reducer---------",action.data);
      const allJobsFromBackendi = action.data
      console.log("allJobsFromBackendi : ", allJobsFromBackendi) 
    const allJobsFromBackend = action.data.jobs
    console.log("allJobsFromBackend : ", allJobsFromBackend)
    const updateStageCounts = (applications) => {
      console.log("applications : ", applications)
      const updatedApplications = [...applications];
      const updatedStageCounts = {
        applied: 0,
        curated: 0,
        shortlisted: 0,
        interviewed: 0,
        offered: 0,
        hired: 0,
        rejected: 0,
      };
      // optimized code
      updatedApplications.forEach((application) => {
        if (application.rejected) {
          updatedStageCounts.rejected++; // Corrected this line
        } else if(application.hired){
          updatedStageCounts.hired++; // Corrected this line
        } else if (application.offered) {
          updatedStageCounts.offered++;
        } else if (application.interviewed) {
          updatedStageCounts.interviewed++;
        } else if (application.shortlisted) {
          updatedStageCounts.shortlisted++;
        } else if (application.curated) {
          updatedStageCounts.curated++;
        } else if (application.applied) {
          updatedStageCounts.applied++;
        }
      });
      //not optimized
      // updatedApplications.forEach((application) => {
      //   if (
      //     application.applied &&
      //     !application.curated &&
      //     !application.shortlisted &&
      //     !application.interviewed &&
      //     !application.offered &&
      //     !application.hired &&
      //     !application.rejected
      //   ) {
      //     updatedStageCounts.applied++;
      //   }
      //   if (
      //     application.curated &&
      //     !application.shortlisted &&
      //     !application.interviewed &&
      //     !application.offered &&
      //     !application.hired &&
      //     !application.rejected
      //   ) {
      //     updatedStageCounts.curated++;
      //   }
      //   if (
      //     application.shortlisted &&
      //     !application.interviewed &&
      //     !application.offered &&
      //     !application.hired &&
      //     !application.rejected
      //   ) {
      //     updatedStageCounts.shortlisted++;
      //   }
      //   if (
      //     application.interviewed &&
      //     !application.offered &&
      //     !application.hired &&
      //     !application.rejected
      //   ) {
      //     updatedStageCounts.interviewed++;
      //   }
      //   if (application.offered && !application.hired && !application.rejected) {
      //     updatedStageCounts.offered++;
      //   }
      //   if (application.hired) {
      //     updatedStageCounts.hired++;
      //   }
      //   if (application.rejected) {
      //     updatedStageCounts.rejected++; // Corrected this line
      //   }
      // });
      return updatedStageCounts;
    };
    const jobsWithUpdatedStageCounts = [];
    allJobsFromBackend.forEach((job) => {
      const stageCounts = updateStageCounts(job.applications);
      job.applied = stageCounts.applied;
      job.curated = stageCounts.curated;
      job.shortlisted = stageCounts.shortlisted;
      job.interviewed = stageCounts.interviewed;
      job.offered = stageCounts.offered;
      job.hired = stageCounts.hired;
      job.rejected = stageCounts.rejected;
      jobsWithUpdatedStageCounts.push(job);
    });
    // if(action.data.page==='1') return Object.assign({}, state, { allJobs: jobsWithUpdatedStageCounts, loading: false, totalPages : action.data.totalPages });
    return Object.assign({}, state, { allJobs: action.data.page==="1" ? jobsWithUpdatedStageCounts : [...state.allJobs, ...jobsWithUpdatedStageCounts], loading: false, totalPages : action.data.totalPages });

    case SET_JOB_FILTER_DROPDOWN: 
    console.log("action.data from filter update : ", action.data)
      return Object.assign({}, state, { filter_Dropdown: action.data, loading: true });

    case "UPDATE_FILTER":
      console.log("filter update", action.data);
      return Object.assign({}, state, { filter: action.data });

    case SET_FILTER_JOBS_VALUES:
      console.log("-----reducer ---------", action.data);
      return Object.assign({}, state, { filter_values: action.data });

    case Filter_LOCAL_JOB_Filter:
      let data = state.jobForLocalSearch.filter(
        (job) =>
          action.data.filterByJob.value === job.job_title ||
          action.data.filterByClient.value === job.assignedClient.client_name
      );


      return {
        ...state,
        filter_values: data,
      };

    case SET_SAVED_QUESTIONS:
      return Object.assign({}, state, { current_job: action.data });

    case SET_JOB_CLOSED:
      console.log("state before", state.allJobs);
      let updatedJobAfterDelete = state.allJobs.filter(job => job._id !== action.data._id)
    
      return Object.assign({}, state, { allJobs: updatedJobAfterDelete });

      case SET_JOB_OPENED:
        console.log("state before", state.allJobs);
        let updatedJobAfterOpened = state.allJobs.filter(job => job._id !== action.data._id)
      
        return Object.assign({}, state, { allJobs: updatedJobAfterOpened });

    case UPDATE_JOB:
      // let findJob = state.allJobs.find((job) => job._id === action.jobId);
      // console.log("job reducer",  findJob, "Action.jobId : ", action.jobId );
      const updatedData = state.allJobs.map((job, id) => {
        if (job._id === action.data._id) {
          console.log("find job", job);
          job.applied += 1;
          job.applications = action.data.applications;
          return job;
        }
        return job;
      });
      return {
        ...state,
        allJobs : updatedData 
      };

    case DELETE_JOB:
      let updatedJob = state.allJobs.filter((job) => job._id !== action.jobId);
      console.log("deleted job", updatedJob);
      return {
        ...state,
        allJobs: updatedJob,
      };

    //job update 
    // case UPDATETEMPLATE_JOB:
    //   return {
    //     ...state,
    //     ...state.jobs
    //   },
    //   console.log("Update job template", action.data);

      // case UPDATETEMPLATE_JOB:
      //   console.log("getscoredatazzz", action.Data);
      //   return Object.assign( state,  {curationData: action.Data});

    // let updatedJob = Object.assign()
    default:
      return state;
  }
}
