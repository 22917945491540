import axios from 'axios';
// axios.defaults.baseURL = 'https://staging.app.qrata.ai';
// axios.defaults.baseURL = 'http://localhost:5002';
axios.defaults.baseURL="https://hireapi.qrata.ai"
const instance = axios.create();
instance.interceptors.response.use(response => {return response}, error => {
    if(error.response.status === 401){
        alert('Your session has expired');
        window.location.replace('/signin');
    }
});

export default instance; 