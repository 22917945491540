export const USER_SIGN_UP = "USER_SIGN_UP";
export const USER_SIGN_IN = "USER_SIGN_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

// Comapny
export const CURRENT_COMPANY = "CURRENT_COMPANY";
export const SET_BASIC_COMPANY_ID = "SET_BASIC_COMPANY_ID";
export const GET_COMPANY_DETAIL = "GET_COMPANY_DETAIL";
export const SET_TOTAL_NUMBERS_OF_JOBS = "SET_TOTAL_NUMBERS_OF_JOBS";

//Chats
export const GET_CHATEDUSERS = "GET_CHATEDUSERS";
export const All_Chats = "All_Chats";

export const STABILITY_RATING = "STABILITY_RATING";

//Interview List
export const INTERVIEW_LIST = "INTERVIEW_LIST";

// add team member
export const ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER";
export const SET_TEAM_MEMBERS = "SET_TEAM_MEMBERS";
export const EDIT_MEMBER = "EDIT_MEMBER";
export const DELETE_MEMBER = "DELETE_MEMBER";
export const SET_EMAIL = "SET_EMAIL";
export const SET_SIGNUP_MEMBER = "SET_SIGNUP_MEMBER";

// Job
export const SET_CURRENT_JOB = "SET_CURRENT_JOB";
export const SET_CURRENT_JOB_TO_EMPTY = "SET_CURRENT_JOB_TO_EMPTY";
export const SET_ALL_JOBS = "SET_ALL_JOBS";
export const SET_SAVED_QUESTIONS = "SET_SAVED_QUESTIONS";
export const SET_FILTER_JOBS = "SET_FILTER_JOBS";
export const SET_FILTER_JOBS_VALUES = "SET_FILTER_JOBS_VALUES";
export const SET_JOB_FILTER_DROPDOWN = "SET_JOB_FILTER_DROPDOWN";
export const SET_JOB_CLOSED = "SET_JOB_CLOSED";
export const SET_JOB_OPENED = "SET_JOB_OPENED";
export const UPDATE_JOB = "UPDATE_JOB";
export const DELETE_JOB = "DELETE_JOB";
export const Filter_LOCAL_JOB_Filter = "Filter_LOCAL_JOB_Filter";

// job applications
export const SET_CURRENT_JOB_APPLICATIONS = "SET_CURRENT_JOB_APPLICATIONS";
export const SET_MOVED_CANDIDATES_STAGE = "SET_MOVED_CANDIDATES_STAGE";
export const SET_CANDIDATE_FILTER = "SET_CANDIDATE_FILTER";
export const ADD_CANDIDATE_DOCUMENT = "ADD_CANDIDATE_DOCUMENT";
export const DELETE_CANDIDATE_DOCUMENT = "DELETE_CANDIDATE_DOCUMENT";
export const ADDTEMPLATE_JOB = "ADDTEMPLATE_JOB";
export const UPDATETEMPLATE_JOB = "UPDATETEMPLATE_JOB";
export const REQUEST_SUBMIT = "REQUEST_SUBMIT";

// jobApplication/individualcandidate
export const SET_FETCHED_CANDIDATE_BY_JOB_ID =
  "SET_FETCHED_CANDIDATE_BY_JOB_ID";
export const SET_CANDIDATE_EDIT_BY_ADMIN = "SET_CANDIDATE_EDIT_BY_ADMIN";
export const EDITED_WORKEXPERIENCE = "EDITED_WORKEXPERIENCE";
export const EDITED_EDUCATION = "EDITED_EDUCATION";
//notes
export const SET_APPLICATIONS = "SET_APPLICATIONS";
// interview scedule
export const SET_SCHEDULE_NOW = "SET_SCHEDULE_NOW";
export const SET_INTERVIEW_SCHEDULE = "SET_INTERVIEW_SCHEDULE";
export const CANCEL_INTERVIEW = "CANCEL_INTERVIEW";
export const DELETE_INTERVIEW = "DELETE_INTERVIEW";

// Career
export const SET_CAREER_BRANDING = "SET_CAREER_BRANDING";
export const SET_CAREER_JOBS = "SET_CAREER_JOBS";
export const SET_CAREER_JOB_DETAIL = "SET_CAREER_JOB_DETAIL";
export const JOB_APPLICATION = "JOB_APPLICATION";

// ATS DASHBOARD
export const SET_TOTAL_APPLICANTS_COUNT = "SET_TOTAL_APPLICANTS_COUNT";
export const MAIN_DASHBOARD_FOR_REPORTS = "MAIN_DASHBOARD_FOR_REPORTS";
export const CANDIDATE_REPORTS = "CANDIDATE_REPORTS";
// Talent Pool
export const SET_ALL_TALENT = "SET_ALL_TALENT";
export const SET_FETCH_CAND_TALENT_POOL = "SET_FETCH_CAND_TALENT_POOL";
export const SET_JOB_FOR_TALENT_POOL = "SET_JOB_FOR_TALENT_POOL";
export const TALENT_GPT = "TALENT_GPT";

// export const SET_TALENT_FILTER = 'SET_TALENT_FILTER'
export const ADD_TALENTPOOL_NOW = "ADD_TALENTPOOL_NOW";
export const SET_TALENT_FILTER = "SET_TALENT_FILTER";
export const CREATE_QRATA_NOTES = "CREATE_QRATA_NOTES";
export const SET_QRATA_NOTES = "SET_QRATA_NOTES";
export const DELETE_QRATA_NOTES = "DELETE_QRATA_NOTES";
export const CONTENT_LOADING = "CONTENT_LOADING";
export const CREATE_NOTES = "CREATE_NOTES";
export const CREATE_INTERNAL_NOTES = "CREATE_INTERNAL_NOTES";
export const DELETE_INTERNAL_NOTES = "DELETE_INTERNAL_NOTES";
export const CLIENT_INTERNAL_NOTES = "CLIENT_INTERNAL_NOTES";
export const DELETE_CLIENT_NOTES = "DELETE_CLIENT_NOTES";
export const EDIT_NOTES = "EDIT_NOTES";
export const DELETE_NOTES = "DELETE_NOTES";
export const PERSONALITY_TRAITS = "PERSONALITY_TRAITS";
export const CURATION_DATA = "CURATION_DATA";
export const QRATA_FIT = "QRATA_FIT";
export const UPDATE_DATA = "UPDATE_DATA";
//CLIENT
export const GET_CLIENT = "GET_CLIENT";
export const ADD_CLIENT = "ADD_CLIENT";
export const GET_COMPANY_USER = "GET_COMPANY_USER";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const EDIT_CLIENT = "EDIT_CLIENT";
export const ADD_CANDIDATE_FEEDBACK = "ADD_CANDIDATE_FEEDBACK";
// export const DELETE_QRATA_NOTES = 'DELETE_QRATA_NOTES';

export const ADD_REFERENCE_INFO = "ADD_REFERENCE_INFO";
export const TAKE_REFERENCE_INFO_QUESTION = "TAKE_REFERENCE_INFO_QUESTION";
export const IS_QA_AND_CURATED_PROFILE = "IS_QA_AND_CURATED_PROFILE";
export const LOADING_API = "LOADING_API";
export const ERROR_UPDATING_DATA = "ERROR_UPDATING_DATA";
export const EDITED_RESUME = "EDITED_RESUME";
export const SCORECARD_TEMPLATE = "SCORECARD_TEMPLATE";
export const GETSCORECARD_TEMPLATE = "GETSCORECARD_TEMPLATE";
export const UPDATESCORECARD_TEMPLATE = "UPDATESCORECARD_TEMPLATE";
export const GET_INITIALIZE = "GET_INITIALIZE";
export const ADD_TALENT_SCORECARD = "ADD_TALENT_SCORECARD";
export const GET_TALENT_SCORECARD = "GET_TALENT_SCORECARD";
//options types
export const GET_LOCATION_OPTION = "GET_LOCATION_OPTION";
export const GET_ROLE_OPTION = "GET_ROLE_OPTION";
export const GET_TAG_OPTION = "GET_TAG_OPTION";
export const GET_POD_GROUP_OPTION = "GET_POD_GROUP_OPTION";
export const TALENT_FILTER = "TALENT_FILTER";
export const UPDATE_TALENTFILTER = "UPDATE_TALENTFILTER";
export const GET_MARKETINSIGHTS = "GET_MARKETINSIGHTS"
export const ALL_MAPPED_CANDIDATE = "ALL_MAPPED_CANDIDATE"
export const GET_MAPPED_CANDIDATE = "GET_MAPPED_CANDIDATE"

//Get unlocked talents

export const UNLOCKED_TALENTS = "UNLOCKED_TALENTS"

// ********** header
export const getHeader = function () {
  const tokenData = window.localStorage.getItem("accessToken");
  const headers = {
    Accept: "application/json",
    Authorization: "Bearer " + tokenData,
  };
  return headers;
};
