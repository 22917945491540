import {
  ADD_CLIENT,
  GET_CLIENT,
  GET_COMPANY_USER,
  DELETE_CLIENT,
  EDIT_CLIENT
} from './../types'
import api from './../api/client_api'
import auserApi from './../api/auth_api'

import axios from 'axios'
import {getHeader} from './../types.js'


// adding new client into data
export const addNewClient = (data, company_id) => (dispatch) => {
  console.log("action client", data);
  return api.client.create(data, company_id)
  .then((data) => {
    return dispatch({
      type: ADD_CLIENT,
      data,
    });
  }).catch(err=>{
    console.log("this is error from create client : ", err)
  })
  ;
};

export const getAllClient = (company_id) => (dispatch) => {
  // console.log("company_id",company_id);
  return api.client.getAllClientApi(company_id).then((data) => {
    console.log("GET client Data", data);
    return dispatch({
      type: GET_CLIENT,
      data: data.clients,
    });
  });
};

export const mailCvToClient = (data) => (dispatch) => {
  return api.company.mailCvToClient(data).then((data) => {
    return data;
    // return dispatch(addTeamMeber(data))
  });
};


// ------------------------------------------POD-----------------------------------------
export function getAllPodRoleUsers(company_id) {
  // return fetch('http://localhost:3333/list')
  //   .then(data => data.json())
  return axios.get(`/api/auth/allUsersPod/${company_id}`, { headers: getHeader()}).then(res => res.data)
}

export function getPodMember(company_id,data) {
  // return fetch('http://localhost:3333/list')
  //   .then(data => data.json())
  // console.log("////////////////////////////////",data.podGroup)

  return axios.post(`/api/auth/podmembers/${company_id}`,data.podGroup, { headers: getHeader()}).then(res => res.data)
}

export function getAllPodGroup(company_id) {
  // return fetch('http://localhost:3333/list')
  //   .then(data => data.json())
  return axios.get(`/api/auth/allPodGroup/${company_id}`, { headers: getHeader()}).then(res => res.data)
}

export function getAllNotPodGroupUsers (company_id){
  return axios.get(`/api/auth/allPodFreeUsers/${company_id}`, { headers: getHeader()}).then(res => res.data)
}

export function updatePodGroup (company_id, data){
  // console.log(company_id,"  company_id     ---     ",data)
  // return axios.get(`/api/auth/all PodFreeUsers/${company_id}`, { headers: getHeader()}).then(res => res.data)
 return axios.post(`/api/auth/updatePodGroup/${company_id}`, data, { headers: getHeader()}).then(res => res.data)
}



export function getAllPodLeadDetail(company_Id) {
  console.log("******************************************",company_Id)
  return axios.get(`/api/auth/allPodLeadDetails/${company_Id}`, { headers: getHeader()}).then(res => res.data)
}

export function  getPodmemberOnPod(company_Id,data) {
  console.log("******************************************",company_Id,data)
  return axios.post(`/api/auth/allpodmember/${company_Id}`,{pod_name:data}, { headers: getHeader()}).then(res => res.data)
}

export function  updateDailyStatus(companyId,data) {
  // return "bye";
  console.log("******************************************",companyId,data)
  return axios.post(`/api/auth/dailystatus/${companyId.companyId}`,data, { headers: getHeader()}).then(res => res.data)
}

// const getPodmemberOnPod = async((company_Id,data) => {

//   console.log("******************************************",company_Id,data)
//   return axios.post(`/api/auth/allpodmember/${company_Id}`,{pod_name:data}, { headers: getHeader()}).then(res => res.data)
// })


// editMemberApi: (id, data) =>
//             axios.put(`/api/auth/user/${id}`, data, { headers: getHeader()}).then(res => res.data)

// axios.get(`/api/auth/allUsersPod/${company_id}`, { headers: getHeader()}).then(res => res.data),
// export const getAllPodRoleUsers = (company_id) => dispatch => {
//   console.log(" pod role company_id",company_id);
//   return auserApi.user.getAllPodRoleUsersApi(company_id).then(data => {
//     console.log("getAllPodRoleUsersApi", data)
//     return data;
//     // return dispatch({
//     //   type: GET_COMPANY_USER,
//     //   data
//     // })
//   })
// }

export const getAllUsers = (company_id) => dispatch => {
  console.log("company_id",company_id);
  return auserApi.user.getAllUsersApi(company_id).then(data => {
    console.log("Action Data", data)
    // return data;
    return dispatch({
      type: GET_COMPANY_USER,
      data,
    });
  });
};

export const deleteClient = (id) => (dispatch) => {
  return api.client.deleteClientApi(id).then((data) => {
    return dispatch({
      type: DELETE_CLIENT,
      data,
    });
  });
};

export const editClient = (id, data) => (dispatch) => {
  return api.client.editClientApi(id, data).then((data) => {
    return dispatch({
      type: EDIT_CLIENT,
      data,
    });
  });
};

// export const setBasicCompanyId = (data) => dispatch => {
//   // console.log('setting company',data)
//   return dispatch(setBasicCompanyIdD(data))
// }

// //get whole company detail for company set up page
// export const getCompanyData = (id) => dispatch => {
//   return api.company.getCompanyApi(id).then(data => {
//     console.log('action data', data)
//     return dispatch(getCompanyDetail(data))
//   })
// }

// export const BasicCompanyInfo = (data) => dispatch => {
//   console.log('set basic comapny', data)
//   // return dispatch(setBasicCompany(data))
// }

// // Adding team member
// export const AddMember = (data) => dispatch => {
//   return api.company.AddMemberApi(data).then(data => {
//     return dispatch(addTeamMeber(data))
//   })
// }

// export const getTeamMmbers = (id) => dispatch => {
//   return api.company.getTeamMmbersApi(id).then(data => {
//     return dispatch(setTeamMembers(data))
//   })
// }
